import * as React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Excerpt from "../components/excerpt";
import Tag from "../components/tag";
import { CalendarIcon } from "@heroicons/react/outline";
import { TagIcon } from "@heroicons/react/solid";

export default function BlogPost({ node, isExcerpt }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  return (
    <article className="border rounded p-4 mb-4" key={node.id}>
      <h1 className="text-2xl font-bold border-b mb-4 pb-4">
        <Link to={`/${node.slug}`}>{node.frontmatter.title}</Link>
      </h1>

      {isExcerpt && (
        <>
          <Excerpt node={node} />
          <p className="text-center">
            <Link
              to={`/${node.slug}`}
              className="py-2 px-4 inline-block border rounded-lg text-white hover:text-white font-semibold hover:no-underline bg-green-700 hover:bg-green-800 border-opacity-25"
            >
              Read the rest of this entry
            </Link>
          </p>
        </>
      )}
      {!isExcerpt && node.frontmatter.twitter_card && (
        <Helmet>
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@pyjamacoder" />
          <meta name="twitter:creator" content="@donaldchea" />
          <meta
            name="twitter:url"
            content={`${data.site.siteMetadata.siteUrl}${node.slug}`}
          />
          <meta name="twitter:title" content={node.frontmatter.title} />
          <meta
            name="twitter:description"
            content={node.frontmatter.description}
          />
          <meta
            name="twitter:image"
            content={
              node.frontmatter.image.childImageSharp.gatsbyImageData.images
                .fallback.src
            }
          />
        </Helmet>
      )}
      {!isExcerpt && (
        <MDXProvider>
          <MDXRenderer>{node.body}</MDXRenderer>
        </MDXProvider>
      )}

      <footer className="border-t pt-4">
        <CalendarIcon className="h-5 w-5 -mt-1 inline-block text-red-800" />{" "}
        {node.frontmatter.date}
        <TagIcon className="ml-2 h-5 w-5 -mt-1 inline-block text-yellow-500" />{" "}
        {node.frontmatter.tags && node.frontmatter.tags.length ? (
          node.frontmatter.tags.map((tag) => <Tag key={tag} tag={tag} />)
        ) : (
          <em>none</em>
        )}
      </footer>
    </article>
  );
}
