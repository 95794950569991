import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

/**
 * Adapted from: https://github.com/gatsbyjs/gatsby/discussions/16865#discussioncomment-139989
 *
 * Takes an mdx node and renders an excerpt.
 *
 * It works by looking for a <div data-excerpt /> node in the markdown and then
 * rendering all the children up to that point.
 *
 * The excerpt should not be nested, otherwise this will probably break.
 *
 * node is a graphql mdx node
 */
export default function Excerpt({ node }) {
  return (
    <MDXProvider
      components={{
        wrapper: ({ onlyExcerpt = false, children }) => {
          let updatedChildren = [...children];

          if (onlyExcerpt) {
            const idx = children.findIndex(
              (child) => child.props && child.props["data-excerpt"]
            );

            if (idx !== -1) {
              updatedChildren = updatedChildren.slice(0, idx);
            }
          }

          return <>{updatedChildren}</>;
        },
      }}
    >
      <MDXRenderer onlyExcerpt={true}>{node.body}</MDXRenderer>
    </MDXProvider>
  );
}
